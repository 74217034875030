/* Styles pour le tableau */
.table-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
  
  th {
  }
  
  /* Styles pour les contrôles du tableau */
  .table-controls {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  input.search-input {
    padding: 5px;
    border-radius: 4px;
    width: 200px;
  }
  
  /* Styles pour les contrôles de pagination */
  .pagination-controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
  }
  
  .pagination-controls button {
    padding: 5px 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .pagination-controls button:hover {
  }
  
  .pagination-controls button:disabled {
    cursor: not-allowed;
  }
  
  .pagination-controls select {
    padding: 5px;
  }
  
  .pagination-controls span {
    margin: 0 10px;
    flex-shrink: 0;
  }
  
  /* Styles pour les ellipses */
  .pagination-controls .ellipsis {
    padding: 5px 10px;
    border: none;
    background-color: transparent;
    cursor: default;
  }
  
  /* Styles pour les messages de chargement et d'erreur */
  .loading-message, .error-message {
    text-align: center;
    margin-top: 10px;
  }
  
  .loading-message {
    color: #007bff;
  }
  
  .error-message {
    color: #dc3545;
  }
  