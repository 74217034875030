#sideMenuButton {
  background-color: var(--primary-color);
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
}

#sidebar {
  position: fixed;
  left: 0;
  top: 3rem;
  height: calc(100vh - 3rem);
  margin: 0;
  padding: 1rem;
  background-color: var(--primary-color);
  color: var(--text-primary-color);
  width: 100%;
  transition: left 0.3s;
  z-index: 998;
}

#sidebar.hidden {
  display: block;
  left: -100%;
}

#sidebar .menu-section {
  margin-bottom: 1rem;
}

#sidebar .section-title {
  font-size: 1.2em;
  margin-bottom: 0.5rem;
  color: var(--text-secondary-color);
}

#sidebar .navItem {
  width: 100%;
  display: block;
  background-color: var(--primary-color);
  color: var(--text-primary-color);
  text-align: left;
  cursor: pointer;
  padding: 0.25em 1em;
  margin: 0;
  height: 2em;
}

#sidebar .navItem a {
  display: block;
  height: 100%;
  width: 100%;
}

#sidebar .navItem:hover {
  color: var(--hover-text-primary-color);
}

/** Responsive */
@media (min-width: 768px) {
  #sideMenuButton {
    display: none;
  }

  #sidebar {
    position: fixed;
    display: block;
    width: 15rem;
  }

  #sidebar.hidden {
    display: block;
    left: 0;
  }
}
