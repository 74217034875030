/* Style pour le conteneur de la liste des partenaires */
.partnersListContainerHidden {
  position: relative; /* Important pour le positionnement du masque */
  max-height: 150px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

/* Style pour le masque */
.partnersListContainerHiddenOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5); /* Semi-transparent blanc, ajustez selon le besoin */
  cursor: not-allowed; /* Change le curseur pour indiquer que l'interaction est désactivée */
}

@keyframes slideUp {
  from {
    transform: translateY(100%); /* Commence en étant déplacé de 100% de sa hauteur vers le bas */
  }
  to {
    transform: translateY(0); /* Fini à sa position normale */
  }
}

.shopManagementPanel {
  animation: slideUp 0.25s ease-out; /* Appliquez l'animation avec une durée de 0.5s */
}