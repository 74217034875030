.alert {
    position: fixed;
    bottom: 0;
    right: 100%;
    color: white;
    padding: 10px;
    z-index: 1000;
    transition: right 0.5s ease-in-out;
  }
  
  .alert-slide-in {
    right: 0;
  }
  
  .alert-slide-out {
    right: -100%;
  }
  
  .alert-success {
    background-color: green;
  }
  
  .alert-warning {
    background-color: orange;
  }
  
  .alert-error {
    background-color: red;
  }
  