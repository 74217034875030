.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    position: relative;
    background: white;
    padding: 2em 1em 1em 1em;
    border-radius: 8px;
    box-shadow: var(--box-shadow);
    overflow-y: auto;
    max-height: 80vh;
  }
  .modal-title {
    text-align: center;
    font-weight: 600;
    padding: 0.5em 0 2em 0;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    color: var(--text-primary-color);
  }
  
  .modal-close-button:hover {
    color: var(--text-color);
    background: none;
  }
  
  .modal-content.small {
    width: 300px;
  }
  
  .modal-content.large {
    width: 600px;
  }
  
  .modal-content.xlarge {
    width: 1000px;
  }
  
  @media (max-width: 700px) {
    .modal-content.large {
      max-width: 90%;
    }
  }
  
  @media (max-width: 400px) {
    .modal-content.small {
      max-width: 90%;
    }
  }
  