nav {
  background-color: var(--primary-color);
  color: var(--text-primary-color);
  padding: 1rem;
  font-size: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
}

nav .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

nav .navItems {
  color: var(--text-primary-color);
  display: none;
  margin-right: 1rem;
}

nav .navItem {
  margin-right: 1rem;
}
nav .navItem:hover,
nav .navItem.home {
  color: var(--hover-text-primary-color);
}
nav .navItem.home {
  font-weight: 600;
}
/** Mobile menu and burger menu **/
#mobileNavItems {
  position: fixed;
  top: 3.5rem;
  left: 0;
  z-index: 9999;
  width: 100%;
}
#mobileNavItems .navItem {
  display: block;
  width: 100%;
  background-color: var(--primary-color);
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
}

#menuButton {
  background-color: var(--primary-color);
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
}

.navItem.dropdown {
  position: relative;
  cursor: pointer;
  color: var(--text-primary-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0; /* Aligner à droite par rapport au parent */
  background-color: var(--card-background-color);
  min-width: 15em;
  box-shadow: var(--box-shadow);
  z-index: 1;
}

.dropdown-content.show {
  display: block;
}

.dropdown-item {
  color: var(--text-color);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: var(--card-background-color);
}

.dropdown-item:hover {
  background-color: var(--table-header-background-color);
  color: var(--table-header-text-color);
}

/** Responsive */
@media (min-width: 768px) {
  #menuButton {
    display: none;
  }

  nav .container {
    justify-content: space-between; /* S'assure que les éléments soient poussés aux extrémités */
  }

  nav .navItems {
    display: flex; /* Cela permettra aux éléments de s'aligner horizontalement */
    align-items: center; /* Centre les éléments verticalement dans leur conteneur */
  }

  #mobileNavItems {
    display: none;
  }
}
