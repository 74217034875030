.tooltip-container {
    display: inline-block;
  }
  
  .tooltip-box {
    position: fixed;
    z-index: 1000;
    padding: 5px 10px;
    background-color: black;
    color: white;
    border-radius: 5px;
    white-space: nowrap;
  }
  