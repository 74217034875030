.order-details {
    padding: 20px;
    background-color: var(--card-background-color);
    border-radius: 8px;
    box-shadow: var(--shadow);
    font-family: var(--font-family);
  }
  
  .order-details h2 {
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .order-details p {
    margin: 5px 0;
    color: var(--text-color);
  }
  
  .order-details-section {
    margin-top: 20px;
  }
  
  .order-details-section h3 {
    margin-bottom: 10px;
    color: var(--primary-color);
  }
  
  .order-details-section-item {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--color-light-gray);
    border-radius: 4px;
    background-color: var(--color-lighter-gray);
  }
  
  .order-details-section-item p {
    margin: 3px 0;
    color: var(--text-color);
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .order-details {
      padding: 15px;
    }
  
    .order-details h2, .order-details-section h3 {
      font-size: 1.2em;
    }
  
    .order-details-section-item {
      padding: 8px;
    }
  }
  