.login-form {
  width: 100%;
  max-width: 20rem;
  box-shadow: var(--shadow);
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: var(--hover-text-primary-color);
  border-radius: 0.25rem;
}
.reset-password-form {
  width: 100%;
  max-width: 20rem;
  background-color: var(--hover-text-primary-color);
}
.reset-password-modal .modal-title {
  padding-bottom:0;
}
.form-element-container {
  margin: 1rem 0.5rem;
}
.form-submit-container {
  margin: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
label {
  display: block;
  text-align: center;
  font-weight: 700;
  color: var(--text-color);
  margin-bottom: 0.75rem;
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
input[type=text], input[type=password],input[type=text]:focus, input[type=password]:focus{
  box-shadow: var(--shadow);
  text-align: center;
  color: var(--text-color);
  line-height: 1.25;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  border-width: 1px;
  border-radius: 0.25rem;
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
}